<template>
  <Excel :jurl="jurl" :jtoken="jtoken" />
</template>

<script>
import Excel from "@/views/Excel.vue";
export default {
  name: "ExcelContainer",
  data() {
    return {
      jurl: "",
      jtoken: "",
    };
  },
  created() {
    this.jurl = sessionStorage.jurl;
    this.jtoken = sessionStorage.token;
  },
  // 通过组件渲染wps的 iframe 框架
  components: { Excel },
};
</script>

<style></style>
