<template
  ><div>
    <div class="kk" id="oo"></div>
  </div>
</template>

<script>
export default {
  name: "excel",
  props: ["jurl", "jtoken"],
  mounted() {
    let _this = this;
    const wps = _this.wps.config({
      mode: "normal",
      mount: document.querySelector("#wps"),
      wpsUrl: this.jurl,
    });
    wps.setToken({ token: this.jtoken });
    // let app = wps.Application;
    // console.log(JSON.stringify(app));
  },
};
</script>

<style scoped></style>
